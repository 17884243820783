import React from 'react';
import styles from "./index.module.less";
import {Input, Form, Modal, Table, BackTop, Button} from "antd";
import PageLoading from "../../components/PageLoading";
import PageTitle from "../../components/PageTitle";
import {inject, observer} from "mobx-react";
import {createSite, deleteSite, listSites, updateSite} from "../../service";
import {message} from "../../lib";
import {ExclamationCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';

@inject('stores')
@observer
class Sites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            showEdit: false,
            editingSite: null,
        };

        this.editFormRef = React.createRef();
        this.columns = [
            {
                title: <b>工地名称</b>,
                dataIndex: "name",
                key: "name",
                align: "center",
            },
            {
                title: <b>操作</b>,
                key: "operation",
                align: "center",
                render: (text) => {
                    const editBtn = (
                      <Button
                        onClick={() => this.showEditSite(text)}
                        type={"primary"}
                        key={"edit"}
                        className={styles.operationBtn}
                      >
                          修改
                      </Button>
                    );
                    const delBtn = (
                      <Button
                        onClick={() => this.showDeleteSite(text)}
                        type={"primary"}
                        danger={true}
                        key={"del"}
                        className={styles.operationBtn}
                      >
                          删除
                      </Button>
                    );
                    return [editBtn, delBtn];
                }
            }
        ];
    }


    componentDidMount() {
        this.setState({loading: true}, async () => {
            await this.loadSites();
            this.setState({loading: false});
        })
    }

    loadSites = async () => {
        try {
            const res = await listSites();
            if (res.error) throw res.error;
            if (res && res.data && res.data.list) {
                this.setState({data: res.data.list});
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    // loadSites = async () => {
    //     try {
    //         const res = await listUser();
    //         if (res.error) throw res.error;
    //         if (res && res.data && res.data.list) {
    //             this.setState({data: res.data.list});
    //         }
    //     } catch (e) {
    //         message.error(e.message)
    //     }
    // }

    showEditSite = (record) => {
        console.log(record);
        this.setState({
            editingSite: record,
            showEdit: true
        }, () => {
            if (this.editFormRef.current) {
                this.editFormRef.current.resetFields();
            }
        });
    }

    showDeleteSite = record => {
        console.log(record)
        Modal.confirm({
            title: '确认删除?',
            icon: <ExclamationCircleOutlined />,
            content: '此条目将被永久删除！',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                console.log('OK');
                this.setState({loading: true}, async () => {
                    try {
                        const res = await deleteSite(record.id);
                        if (res.error) throw res.error;
                        message.success("删除成功");
                        await this.loadSites();
                    } catch (e) {
                        message.error(e.message);
                    } finally {
                        this.setState({loading: false});
                    }
                })
            }
        });
    }

    showAddSite = () => {
        this.setState({
            editingSite: {},
            showEdit: true
        }, () => {
            if (this.editFormRef.current) {
                this.editFormRef.current.resetFields();
            }
        });
    }

    onFormFinish = async (e) => {
        console.log("Submit", e)
        this.setState({loading: true}, async () => {
            try {
                const {name} = e;
                const id = this.state.editingSite ? this.state.editingSite.id : null;
                console.log("id:", id);
                const body = id ? {id, name} : {name}
                const res = await (id ? updateSite(body) : createSite(body));
                if (res.error) throw res.error;
                await this.loadSites();
                this.setState({
                    editingSite: null,
                    showEdit: false
                });
                message.success("成功");
            } catch (e) {
                message.error("失败：" + e.message);
            } finally {
                console.log("finish")
                this.setState({loading: false})
            }
        })
    }

    onEditModalCancel = () => {
        this.setState({
            editingSite: null,
            showEdit: false
        })
    }

    onEditModalOk = () => {
        return this.editFormRef.current.submit();
    }

    render() {
        const {data, showEdit, editingSite} = this.state;
        return (
            <div>
                <BackTop />
                <PageLoading loading={this.state.loading}/>
                <PageTitle>工地 | Sites</PageTitle>
                <div className={styles.main}>
                    <div className={styles.buttons}>
                        <Button
                          type={"primary"}
                          onClick={this.showAddSite}
                        >
                            <PlusCircleOutlined />新建
                        </Button>
                    </div>
                    <Table
                        // pagination={false}
                        size={"middle"}
                        columns={this.columns}
                        dataSource={data}
                        rowKey={"id"}
                    />
                </div>

                <Modal
                  visible={showEdit}
                  title={editingSite && editingSite.id ? "编辑工地 | Edit site" : "新增工地 | Create site"}
                  onCancel={this.onEditModalCancel}
                  onOk={this.onEditModalOk}
                >
                    <Form
                      ref={this.editFormRef}
                      onFinish={this.onFormFinish}
                      labelCol={{span: 5}}
                      wrapperCol={{ span: 19 }}
                      initialValues={{name: editingSite ? editingSite.name : ""}}
                    >
                        <Form.Item
                          label={"名称 | Name"}
                          name={"name"}
                          rules={[{required: true, message: "请输入工地名称"}]}
                        >
                            <Input className={styles.formInput}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Sites;
