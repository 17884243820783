import {Spin} from "antd";
import React from "react";

export default class PageLoading extends React.Component {
    render() {
        return (
            <Spin style={{ position:'fixed',top:'50%', left:'50%', zIndex:10 }} spinning={this.props.loading} size='large'/>
        )
    }
}
