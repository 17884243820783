const env = process.env.NODE_ENV;

let baseUrl;

if (env === 'production') {
    baseUrl = "https://orientconstruction.work/admin_api/v1/";
} else if (env === 'development') {
    // baseUrl = "https://139.180.169.117/admin_api/v1/"
    // baseUrl = "http://jp-tyo-dvm-2.sakurafrp.com:53486/admin_api/v1/"
    baseUrl = "http://localhost:802/admin_api/v1/";
} else {
    throw new Error('Invalid env value');
}

const frontEndUrlSymbol = () => {
    let urlArr = window.location.href.split('/');
    return urlArr.length > 2 ? urlArr[2] + '/' : '/';
};

const debug = env === 'development';

export default {
    CURRENT_ENV: env,

    DEBUG: debug,

    ENV: {
        PRODUCTION: 'production',
        DEVELOPMENT: 'development',
    },

    TOKEN_SYMBOL: frontEndUrlSymbol() + "INFO",

    MENU_ITEMS: {
        USERS: "/",
        RECORDS: "/records",
        SITES: "/sites",
        TASKS: "/tasks"
        // PROFILE: "/profile",
    },

    BASE_URL: baseUrl,

    API_REQUEST_TIME_OUT: 25000,

    API_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss ZZ",
    API_DATE_FORMAT: "YYYY-MM-DD",
    SERVER_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss Z",

    TIME_RANGE_OPTIONS: {
        DAY: 1,
        WEEK: 2,
        MONTH: 3
    },
    RECORD_TYPE: {
        SIGN_IN: 1,
        SIGN_OUT: 2
    },
    MAP_KEY: "AIzaSyA01eFJoDenRtUoS708KuE1r23ihDxweIE",
}
