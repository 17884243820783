import React from 'react';
import styles from "./index.module.less";
import {Input, Form, Modal, Table, BackTop, Button} from "antd";
import PageLoading from "../../components/PageLoading";
import PageTitle from "../../components/PageTitle";
import {inject, observer} from "mobx-react";
import {deleteUser, listUser, updateUser} from "../../service";
import {message} from "../../lib";
import {ExclamationCircleOutlined} from '@ant-design/icons';

@inject('stores')
@observer
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            editingUser: null,
            showEdit: false,
        };
        this.editFormRef = React.createRef();
        this.columns = [
            {
                title: <b>真实姓名</b>,
                dataIndex: "realName",
                key: "name",
                align: "center",
                // sorter: (a, b) => {
                //     return a.realName.length - b.realName.length
                // },
            },
            {
                title: <b>操作</b>,
                key: "operation",
                align: "center",
                render: (text) => {
                    const editBtn = (
                        <Button
                            onClick={() => this.showEditUser(text)}
                            type={"primary"}
                            key={"edit"}
                            className={styles.operationBtn}
                        >
                            修改
                        </Button>
                    );
                    const delBtn = (
                        <Button
                            onClick={() => this.showDeleteUser(text)}
                            type={"primary"}
                            danger={true}
                            key={"del"}
                            className={styles.operationBtn}
                        >
                            删除
                        </Button>
                    );
                    return [editBtn, delBtn];
                }
            }
        ];
    }

    componentDidMount() {
        this.setState({loading: true}, async () => {
            await this.loadUsers();
            this.setState({loading: false});
        })
    }

    loadUsers = async () => {
        try {
            const res = await listUser();
            if (res.error) throw res.error;
            if (res && res.data && res.data.list) {
                this.setState({data: res.data.list});
            }
        } catch (e) {
            message.error(e.message)
        }
    }

    showEditUser = (record) => {
        console.log(record);
        this.setState({
            editingUser: record,
            showEdit: true
        });
    }

    showDeleteUser = record => {
        console.log(record)
        Modal.confirm({
            title: '确认删除用户?',
            icon: <ExclamationCircleOutlined />,
            content: '此用户将被永久删除！',
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                console.log('OK');
                this.setState({loading: true}, async () => {
                    try {
                        const res = await deleteUser(record.openId);
                        if (res.error) throw res.error;
                        message.success("删除成功");
                        await this.loadUsers();
                    } catch (e) {
                        message.error(e.message);
                    } finally {
                        this.setState({loading: false});
                    }
                })
            }
        });
    }

    onFormFinish = async (e) => {
        console.log("Submit", e)
        this.setState({loading: true}, async () => {
            try {
                const {realName} = e;
                const {openId} = this.state.editingUser;
                const res = await updateUser({
                    openId,
                    realName
                });
                if (res.error) throw res.error;
                await this.loadUsers();
                this.setState({
                    editingUser: null,
                    showEdit: false
                });
                message.success("修改成功");
            } catch (e) {
                message.error("修改失败：" + e.message);
            } finally {
                console.log("finish")
                this.setState({loading: false})
            }
        })
    }

    onEditModalCancel = () => {
        this.setState({
            editingUser: null,
            showEdit: false
        })
    }

    onEditModalOk = () => {
        return this.editFormRef.current.submit();
    }

    render() {
        const {data, showEdit, editingUser} = this.state;
        return (
            <div>
                <BackTop />
                <PageLoading loading={this.state.loading}/>
                <PageTitle>用户 | User</PageTitle>
                <div className={styles.main}>
                    <Table
                        // pagination={false}
                        size={"middle"}
                        columns={this.columns}
                        dataSource={data}
                        rowKey={"openId"}
                    />
                </div>
                <Modal
                    visible={showEdit}
                    title={"修改用户"}
                    onCancel={this.onEditModalCancel}
                    onOk={this.onEditModalOk}
                >
                    <Form
                        ref={this.editFormRef}
                        onFinish={this.onFormFinish}
                        labelCol={{span: 4}}
                        wrapperCol={{ span: 20 }}
                        initialValues={{realName: editingUser ? editingUser.realName : ""}}
                    >
                        <Form.Item
                            label={"真实姓名"}
                            name={"realName"}
                            rules={[{required: true, message: "请输入真实姓名"}]}
                        >
                            <Input className={styles.formInput}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

export default Users;
