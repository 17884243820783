import axios from 'axios';
import config from '../config';

const baseUrl = config.BASE_URL

class HttpRequest {
    constructor(baseUrl = '') {
        this.baseUrl = baseUrl;
        this.queue = {};
    }

    setStore(store) {
        this.store = store;
    }

    getInsideConfig() {
        let token = this.store.AuthStore.getToken ? this.store.AuthStore.getToken : '';
        return {
            baseURL: this.baseUrl,
            headers: {
                Authorization: token,
                //
            },
            withCredentials: true,
            timeout: config.API_REQUEST_TIME_OUT,
        };
        // return config
    }

    // resetBaseUrl(url) {
    //     this.baseUrl = url;
    // }

    destroy(url) {
        delete this.queue[url];
    }

    interceptors(instance, url) {

        instance.interceptors.request.use(config => {
            config.metadata = { startTime: new Date()};
            this.queue[url] = true;
            return config;
        }, error => {
            // console.log("instance.interceptors.request ERROR:", error);
            return Promise.reject(error);
        });

        instance.interceptors.response.use(res => {
            this.destroy(url);
            // console.log("instance.interceptors.response:", res)
            const { data, status, headers } = res;

            res.config.metadata.endTime = new Date();

            if ( status !== 200){
                throw new Error("Access API failure, Status Code :" + status)
            }
            if (headers && headers.authorization) {
                data.token = headers.authorization;
            }
            if (data.code) {
                if ( data.code === 200){
                    data.error = null;
                } else if (data.code === 401) {
                    data.error = new Error(data.msg);
                    data.error.code = data.code;
                    this.store.AuthStore.localLogout();
                    // console.log("from api.js: logout executed!!! ")
                } else {
                    data.error = new Error(data.msg);
                    data.error.code = data.code;
                }
                return data;
            } else {
                return data;
            }
        }, (error) => {
            // console.log("instance.interceptors.response ERROR:", error.response)
            this.destroy(url);
            return Promise.reject(error.response && error.response.data ? error.response.data : error)
        })
    }
    request(options) {
        if (config.CURRENT_ENV === config.ENV.DEVELOPMENT) {
            console.log('API request sending to ', options.url);
        }
        const instance = axios.create();
        let insideConfig = this.getInsideConfig();
        let {headers} = insideConfig;
        // console.log("inside headers:", headers);
        // console.log("options headers:", options.headers);
        if (options.headers) {
            headers = {...headers, ...options.headers}
        }
        // console.log("merged headers:", headers)
        options.headers = headers;
        options = Object.assign(insideConfig, options);
        // console.log(">> options:", options);
        this.interceptors(instance, options.url);
        return instance(options)
    }
}


const api = new HttpRequest(baseUrl);

export default api;

