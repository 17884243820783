import React from "react";
import MarkerImg from "../../static/img/location.png";
import styles from "./index.module.less";

class MapMarker extends React.Component {
    render() {
        return (
            <div>
                <span className={styles.text}>{this.props.text}</span>
                <img
                    src={MarkerImg}
                    alt="map marker"
                    className={styles.img}
                />
            </div>

        )
    }
}

export default MapMarker;
