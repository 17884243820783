import {message as msg} from 'antd';
import * as numeral from "numeral";
import moment from 'moment';
import config from '../config';
import CacheStorage from "./CacheStorage";

export const dd = (...ps) => {
    if (!config.DEBUG) return;
    // eslint-disable-next-line no-useless-concat
    ps.unshift("Debug " + "[" + new Date() + "]");
    let debug = console.log.bind(window.console);
    debug(...ps);
};

export const message = {
    globalError: function (...ps) {
        msg.error(...ps);
        dd("Message.error:", ...ps);
        window.location.href = '/';
    },
    error: function (...ps) {
        dd("Message.error:", ...ps);
        msg.error(...ps);
    },
    warning: function (...ps) {
        dd("Message.warning:", ...ps);
        msg.warning(...ps);
    },
    info: function (...ps) {
        dd("Message.info:", ...ps);
        msg.info(...ps);
    },
    success: function (...ps) {
        dd("Message.success:", ...ps);
        msg.success(...ps);
    },
};

export const getRounding = (number, digit) => {
    if ( typeof digit === 'undefined')  digit = 2;
    let final, isNegativeNum;
    if (number < 0) {
        isNegativeNum = true;
        number = - number;
    }
    if (digit > 4) {
        let num = Math.pow(10, digit);
        final = Math.round(numeral(number).value() * num) / num;
    } else {
        let num = Math.pow(10, digit + 4);
        let temp = numeral(number).value() * num;
        for (let i = 4  ; i > 0; i--) {
            temp = Math.round(temp) / 10;
        }
        final = Math.round(temp) / Math.pow(10, digit);
    }
    if(isNegativeNum) {
        final = -final;
    }
    return final;
};

export const getRounding2 = (number) => getRounding(number, 2);

export const formatTime = time => moment(time, "YYYY-MM-DD HH:mm:ss Z").format('DD/MM/YYYY HH:mm:ss a');

export const formatDate = date => moment(date, "YYYY-MM-DD HH:mm:ss Z").format('DD/MM/YYYY');

export const utcToLocalTime = utcTime => moment.utc(utcTime, config.SERVER_TIME_FORMAT).local().format("DD/MM/YYYY HH:mm:ss")

export const formatAmount = (value) => {
    if (value == null) return "--";
    const amount = getRounding2(value);
    let res;
    if (value >= 0) {
        res = numeral(amount).format("$0,0.00");
    } else {
        res = numeral(amount).format("$0,0.00");
    }
    return res;
};

export const formatAmountWithSymbol = (value) => {
    if (value == null) return "--";
    const amount = getRounding2(value);
    let res;
    if (value > 0) {
        res = numeral(amount).format("+ $0,0.00");
    } else if (value < 0) {
        res = numeral(amount).format("- $0,0.00");
    } else {
        res = numeral(amount).format("$0,0.00");
    }
    return res;
};

export const getAmountTitle = amount => {
    if (!amount) {
        return "";
    }
    if (amount > 0) {
        return "Income";
    }
    return "Cost";
};

export {CacheStorage};
