import React from 'react';
import styles from "./index.module.less";
import {Button, DatePicker, Divider, Image, Input, Modal, Select, Table, Tooltip, Upload} from "antd";
import * as FileSaver from "file-saver";
import PageLoading from "../../components/PageLoading";
import PageTitle from "../../components/PageTitle";
import {inject, observer} from "mobx-react";
import {
  deleteImgLink,
  getImgByImgLinkId,
  getRecordById,
  listRecords,
  listSites,
  listTasks,
  listUser,
  updateRecord,
  uploadFile,
} from "../../service";
import {message, utcToLocalTime} from "../../lib";
import {DeleteOutlined, DownloadOutlined, PaperClipOutlined, QuestionCircleOutlined, UploadOutlined} from '@ant-design/icons';
import moment from "moment";
import config from "../../config";
import GoogleMapReact from 'google-map-react';
import MapMarker from "../../components/MapMarker";

const {TextArea} = Input;

const office = {
  lat: -36.7486, lng: 174.7042    // office: -36.74859551387674, 174.70420500064543
}

const imgTypes = ["jpg", "jpeg", "png", "gif", "bmp"];

@inject("stores")
@observer
class Records extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
      sites: [],
      tasks: [],
      records: [],
      startTime: null,
      endTime: null,
      showLongitude: null,
      showLatitude: null,
      recordType: undefined,
      recordTime: "",
      filteredInfo: [],
      editingRecord: null,
      showModal: false,
      imgToShow: null,
      // imgType: null,
      showImg: false,
      fileList: [],
      removedFileList: []
    };
    this.editFormRef = React.createRef();
    this.columns = [
      {
        title: "真实姓名",
        key: "realName",
        dataIndex: "realName",
        align: "center",
        render: (text, record) => {
          const {openId} = record;
          return this.getRealNameByOpenId(openId);
        },
      }, {
        title: "登入时间",
        align: "center",
        dataIndex: "timeIn",
        key: "timeIn",
        render: (text) => {
          return text ? utcToLocalTime(text) : "-"
        }
      }, {
        title: (
          <Tooltip title={"点击位置以在地图上查看"}>
            <span>登入位置</span> <QuestionCircleOutlined />
          </Tooltip>
        ),
        key: "locationIn",
        dataIndex: "locationIn",
        align: "center",
        render: (text, record) => {
          const {latitudeIn, longitudeIn, timeIn} = record;
          return (
            longitudeIn || latitudeIn ? <span
              className={styles.locationSpan}
              title={"在地图上查看"}
              onClick={() => this.setLocation(longitudeIn, latitudeIn, config.RECORD_TYPE.SIGN_IN, timeIn)}
            >
              {this.makeLocation(longitudeIn, latitudeIn)}
            </span> : "-"
          )
        }
      }, {
        title: "登出时间",
        key: "timeOut",
        dataIndex: "timeOut",
        align: "center",
        render: (text) => {
          return text ? utcToLocalTime(text) : "-"
        }
      }, {
        title: (
          <Tooltip title={"点击位置以在地图上查看"}>
            <span>登出位置</span> <QuestionCircleOutlined />
          </Tooltip>
        ),
        key: "locationOut",
        align: "center",
        render: (text, record) => {
          const {latitudeOut, longitudeOut, timeOut} = record;
          return (
            longitudeOut || latitudeOut ? <span
              className={styles.locationSpan}
              title={"在地图上查看"}
              onClick={() => this.setLocation(longitudeOut, latitudeOut, config.RECORD_TYPE.SIGN_OUT, timeOut)}
            >
              {this.makeLocation(longitudeOut, latitudeOut)}
            </span> : "-"
          )
        }
      }, {
        title: "工地",
        key: "site",
        align: "center",
        render: (record) => {
          return this.getSiteNameById(record.siteId);
        },
      }, {
        title: "任务",
        key: "task",
        align: "center",
        render: (record) => {
          return this.getTaskNameById(record.taskId);
        },
      }, {
        title: <div className={styles.tableTitleCenter}>备注</div>,
        key: "comment",
        dataIndex: "comment",
        align: "left",
        // width: "10%"
      }, {
        title: <div className={styles.tableTitleCenter}>文件</div>,
        key: "files",
        align: "left",
        width: 250,
        render: (record) => {
          return this.renderImageList(record);
        }
      }, {
        title: "操作",
        key: "operate",
        align: "center",
        render: (record) => {
          return [
            <Button
              className={styles.btn}
              type={"primary"}
              key={"check"}
              onClick={() => this.onEditClick(record)}
            >
              编辑
            </Button>
          ];
        }
      }
    ];
  }

  componentDidMount() {
    this.setState({
      loading: true,
      endTime: this.makeDateMoment(moment()),
      // startTime: this.makeDateMoment(moment()),
    }, async () => {
      await this.loadUsers();
      await this.loadSites();
      await this.loadTasks();
      await this.loadRecords();
      this.setState({loading: false});
    })
  }

  getSiteNameById = id => {
    const {sites} = this.state;
    for (const site of sites) {
      if (site.id === id) {
        return site.name;
      }
    }
    return "";
  }

  makeLocation = (longitude, latitude) => {
    return `经度：${longitude ? longitude : "-"}, 纬度：${latitude ? latitude : "-"}`;
  }

  getTaskNameById = id => {
    const {tasks} = this.state;
    for (const task of tasks) {
      if (task.id === id) {
        return task.name;
      }
    }
    return "";
  }

  getRealNameByOpenId = openId => {
    const {users} = this.state;
    for (const user of users) {
      if (user.openId === openId) {
        return user.realName;
      }
    }
    return "[用户已被删除]"
  }

  onEditClick = record => {
    let editingRecord = {};
    Object.assign(editingRecord, record);
    this.setState({
      editingRecord,
      showModal: true,
    });
  }

  onCancelModalClick = () => {
    this.setState({
      editingRecord: null,
      showModal: false,
      fileList: [],
      removedFileList: []
    });
  }


  onEditModalSubmit = async () => {
    const {editingRecord, fileList, removedFileList} = this.state;
    this.setState({
      loading: true,
    }, async () => {
      let haveError = false;
      // delete files:
      for (const id of removedFileList) {
        try {
          const res = await deleteImgLink(id);
          if (res.error) throw res.error;
          message.success("文件已删除");
        } catch (e) {
          haveError = true;
          message.error(e.message);
        }
      }

      // upload files:
      for (const file of fileList) {
        const formData = new FormData();
        formData.append("pic", file.originFileObj);

        try {
          const res = await uploadFile(editingRecord.id, formData);
          if (res.error) throw res.error;
          message.success("文件已上传：", file.name);
        } catch (e) {
          haveError = true;
          message.error(e.message);
        }
      }

      // update record:
      try {
        const res = await updateRecord(editingRecord);
        if (res.error) throw res.error;
        message.success("记录已更新");
      } catch (e) {
        haveError = true;
        message.error(e.message);
      }

      // get updated record info:
      await this.updateEditedRecord(editingRecord.id);

      if (!haveError) {
        this.onCancelModalClick();
      } else {
        let newEditingRecord = null;
        for (const record of this.state.records) {
          if (record.id === editingRecord.id) {
            newEditingRecord = record;
            break;
          }
        }
        this.setState({editingRecord: newEditingRecord});
      }
    });
    // console.log(editingRecord);
  }

  setImgToShow = (stream, imgType) => {
    const blob = new Blob([stream], {type: imgType});
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = (e) => {
      this.setState({
        imgToShow: e.target.result
      }, ()=> {
        console.log(this.state);
        this.setState({
          showImg: true,
        })
      })
    }
  }

  makeDateMoment = time => {
    console.log("Receive:", time ? time.format() : "")
    const newMoment = time ? moment(time.format("YYYY-MM-DD"), "YYYY-MM-DD").utc().local() : "";
    console.log("Return:", newMoment ? newMoment.format() : "")
    return newMoment;
  }

  loadUsers = async () => {
    try {
      const res = await listUser();
      if (res.error) throw res.error;
      if (res && res.data && res.data.list) {
        this.setState({users: res.data.list});
      }
    } catch (e) {
      message.error(e.message);
    }
  }

  loadSites = async () => {
    try {
      const res = await listSites();
      if (res.error) throw res.error;
      if (res && res.data && res.data.list) {
        this.setState({sites: res.data.list});
      }
    } catch (e) {
      message.error(e.message);
    }
  }

  loadTasks = async () => {
    try {
      const res = await listTasks();
      if (res.error) throw res.error;
      if (res && res.data && res.data.list) {
        this.setState({tasks: res.data.list});
      }
    } catch (e) {
      message.error(e.message);
    }
  }

  loadRecords = async () => {
    const {startTime, endTime} = this.state;
    try {
      const startTimeStr = startTime ? startTime.utc().format(config.API_TIME_FORMAT) : "";
      const endTimeStr = endTime ? endTime.utc().format(config.API_TIME_FORMAT) : "";
      const res = await listRecords(startTimeStr, endTimeStr, "");
      if (res.error) throw res.error;
      if (res.data && res.data.list) {
        const {list} = res.data;
        let records = list ? list : [];
        this.setState({records});
      }
    } catch (e) {
      message.error(e.message);
    }
  }

  updateEditedRecord = async (recordId) => {
    this.setState({loading: true}, async () => {
      try {
        const res = await getRecordById(recordId);
        if (res.error) throw res.error;
        let {records} = this.state;

        const newRecords = records.map((item) => item.id === recordId ? res.data : item);
        console.log("newRecords:", newRecords);
        this.setState({records: newRecords}, () => {
          console.log(records)
        });
      } catch (e) {
        message.error(e.message);
      } finally {
        this.setState({loading: false});
      }
    });
  }

  setLocation = (longitude, latitude, recordType, recordTime) => {
    this.setState({
      showLongitude: longitude,
      showLatitude: latitude,
      recordType,
      recordTime
    })
  }

  onEditModalCancel = () => {
    this.setState({
      editingUser: null,
      showEdit: false
    })
  }

  onEditModalOk = () => {
    return this.editFormRef.current.submit();
  }

  onStartTimeChange = value => {
    this.setState({startTime: this.makeDateMoment(value), loading: true}, async () => {
      await this.loadRecords();
      this.setState({loading: false});
    });
  }

  onEndTimeChange = value => {
    this.setState({endTime: this.makeDateMoment(value), loading: true}, async () => {
      await this.loadRecords();
      this.setState({loading: false});
    });
  }

  onNameChange = (value) => {
    this.setState({
      filteredInfo: value
    });
  }

  onCommentEdit = (e) => {
    let {editingRecord} = this.state;
    editingRecord.comment = e ? e.target.value : "";
    this.setState({
      editingRecord
    });
    console.log("editingRecord:", editingRecord);
    console.log("records:", this.state.records);
  }

  onUploadClick = (e) => {
    console.log("onUploadClick:", e)
  }

  onDownloadImgClick = async (imgLink) => {
    this.setState({loading: true}, async () => {
      try {
        let res = await getImgByImgLinkId(imgLink.id);
        if (res.error) throw res.error;
        console.log("onDownloadImgClick:", res)
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (e) => {
          const url = e.target.result
          const tmpA = document.createElement("a");
          tmpA.download = imgLink.filename || "download";
          tmpA.href = url; //绑定a标签
          tmpA.click(); //模拟点击实现下载
          setTimeout(function () { //延时释放
            URL.revokeObjectURL(url); //用URL.revokeObjectURL()来释放这个object URL
          }, 100);
        }
      } catch (e) {
        console.log("onDownloadImgClick ERROR:", e);
        message.error(e.message);
      } finally {
        this.setState({loading: false});
      }
    })
  }

  onImgClick = async (imgLink) => {
    this.setState({
      loading: true,
    }, async () => {
      try {
        const res = await getImgByImgLinkId(imgLink.id);
        if (res.error) throw res.error;
        console.log("onImgClick:", res)
        this.setImgToShow(res, imgLink.contentType);
      } catch (e) {
        console.log("onImgClick ERROR:", e);
        message.error(e.message);
      } finally {
        this.setState({loading: false});
      }
    });
  }

  onExportClick = async (e) => {
    console.log("onExportClick:", e);
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();
    const sheet = workbook.addWorksheet("record");
    sheet.columns = [
      {header: "姓名", key: "realName"},
      {header: "登入时间", key: "timeIn", width: 25},
      {header: "登入位置", key: "locationIn", width: 25},
      {header: "登出时间", key: "timeOut", width: 25},
      {header: "登出位置", key: "locationOut", width: 25},
      {header: "工地", key: "site"},
      {header: "任务", key: "task"},
      {header: "备注", key: "comment", width: 25},
    ];
    const data = this.makeExcelData();
    sheet.addRows(data);
    let fileName = "records";
    const {startTime, endTime} = this.state;
    if (startTime) {
      fileName += "_" + startTime.local().format('DD/MM/YYYY');
    }
    if (endTime) {
      fileName += startTime ? "-" : "_";
      fileName += endTime.local().format('DD/MM/YYYY');
    }

    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"});
      FileSaver.saveAs(blob, fileName);
    });
  }

  makeExcelData = () => {
    const {sites, tasks, records, users} = this.state;
    let dataList = [];
    for (const record of records) {
      const {openId, timeIn, timeOut, latitudeIn, latitudeOut, longitudeIn, longitudeOut, taskId, siteId, comment} = record;
      let data = {};
      data["timeIn"] = utcToLocalTime(timeIn);
      data["timeOut"] = utcToLocalTime(timeOut);
      data["locationIn"] = this.makeLocation(longitudeIn, latitudeIn);
      data["locationOut"] = this.makeLocation(longitudeOut, latitudeOut);
      data["comment"] = comment;
      for (const user of users) {
        if (user.openId === openId) {
          data["realName"] = user.realName;
          break;
        }
      }

      for (const site of sites) {
        if (site.id === siteId) {
          data["site"] = site.name;
          break;
        }
      }

      for (const task of tasks) {
        if (task.id === taskId) {
          data["task"] = task.name;
          break;
        }
      }
      dataList.push(data);
    }
    return dataList;
  }

  cancelRemoveFile = id => {
    let {removedFileList} = this.state;
    const index = removedFileList.indexOf(id);
    removedFileList.splice(index, 1);
    this.setState({removedFileList});
  }

  removeFile = id => {
    if (id != null) {
      let {removedFileList} = this.state;
      removedFileList.push(id);
      this.setState({removedFileList});
    }
  }

  renderImageList = (record) => {
    // const {editingRecord} = this.state;
    if (!record) {
      return "";
    }
    let images = [];
    for (const imgLink of record.imgLinks) {
      const {filename} = imgLink;
      images.push(
        <div
          key={imgLink.id}
        >
          <div className={styles.imgPreviewFrame}>
            <PaperClipOutlined />
            <span
              className={styles.filenameClickable}
              onClick={async () => {await this.onImgClick(imgLink)}}
              title={"查看 "+filename}
            >
              {filename}
            </span>
            <DownloadOutlined
              className={styles.downloadImgBtn}
              onClick={async () => (await this.onDownloadImgClick(imgLink))}
              title={"下载"}
            />
          </div>
        </div>
      );
    }
    return images;
  }

  renderEditingImageList = () => {
    const {editingRecord, removedFileList} = this.state;
    if (!editingRecord) {
      return "";
    }
    let images = [];
    for (const imgLink of editingRecord.imgLinks) {
      const {id, filename} = imgLink;
      if (removedFileList.includes(id)) {
        images.push(
          <div
            key={imgLink.id}
            className={styles.imgPreviewRemoved}
            onClick={() => this.cancelRemoveFile(imgLink.id)}
          >
            <PaperClipOutlined />
            <span className={styles.filename}>{filename}</span>
          </div>
        );
      } else {
        images.push(
          <div
            key={imgLink.id}
            className={styles.imgPreview}
          >
            <PaperClipOutlined />
            <span className={styles.filename}>{filename}</span>
            <DeleteOutlined
              className={styles.deleteBtn}
              title={"删除"}
              onClick={() => {this.removeFile(imgLink.id)}}
            />
          </div>
        );
      }
    }
    return images;


  }

  getOptions = () => {
    const {users} = this.state;
    if (!users) {
      return [];
    }
    let options = [];
    this.state.users.forEach((user) => {
      // console.log(user)
      options.push(
        <Select.Option
          key={user.openId}
          value={user.realName}
        >
          {user.realName}
        </Select.Option>
      );
    });
    return options;
  }

  getMarkerText = () => {
    const {showLongitude, showLatitude, recordTime, recordType} = this.state;
    if (showLongitude && showLatitude) {
      return (
        <span>
                    {recordType === config.RECORD_TYPE.SIGN_IN ? "登入" : "登出"}<br/>
          {recordTime}
                </span>
      );
    } else {
      return "ORIENT CONSTRUCTION";
    }
  }

  getData = () => {
    console.log("getData")
    const {records, users, filteredInfo} = this.state;
    if (!filteredInfo || filteredInfo.length === 0) {
      return records;
    } else {
      let res = [];
      for (const record of records) {
        const {openId} = record;
        let realName = null;
        for (const user of users) {
          if (user.openId === openId) {
            realName = user.realName;
            break;
          }
        }
        console.log("record:", record, filteredInfo.indexOf(realName))
        if (filteredInfo.indexOf(realName) >= 0) {
          res.push(record);
        }
      }
      return res;
    }
  }

  getAcceptTypesString = () => {
    let string = "";
    for (const type of imgTypes) {
      string = string + "." + type + ", ";
    }
    return string;
  }

  render() {
    const {
      startTime,
      endTime,
      showLongitude,
      showLatitude,
      showModal,
      editingRecord,
      loading,
      fileList,
      imgToShow,
      showImg,
      // imgType
    } = this.state;
    const defaultCenter = office;       // office: -36.74859551387674, 174.70420500064543
    const defaultZoom = 14.5;
    const data = this.getData();
    const props = {
      name: 'pic',
      multiple: false,
      accept: this.getAcceptTypesString(),
      fileList,
      headers:  {Authorization: this.props.stores.AuthStore.getToken ? this.props.stores.AuthStore.getToken : ""},
      beforeUpload: file => {
        const fileType = file.name.split('.').pop().toLowerCase();
        if (!imgTypes.includes(fileType)) {
          message.error("不支持的文件格式：" + fileType);
          return false;
        }
        const {editingRecord} = this.state;
        if (editingRecord) {
          const {imgLinks} = editingRecord;
          for (const imgLink of imgLinks) {
            if (imgLink.filename === file.name) {
              message.error("文件已存在。");
              return false;
            }
          }
        }
        return false;
      },
      onChange: async (info) => {
        console.log("onChange:", info);
        const {imgLinks} = editingRecord;
        let existed = false;
        for (const imgLink of imgLinks) {
          if (imgLink.filename === info.file.name) {
            existed = true;
            break;
          }
        }
        if (!existed) {
          this.setState({fileList: info.fileList});
        }

        // await this.updateEditedRecord(editingRecord.id);
      },
    };
    return (
      <div>
        <PageLoading loading={loading}/>
        <PageTitle>记录 | Records</PageTitle>
        <div className={styles.filters}>
          <span className={styles.filterText}>日期：</span>
          <DatePicker
            className={styles.filter}
            placeholder={"开始日期"}
            value={startTime}
            onChange={this.onStartTimeChange}
          />
          <span className={styles.filterText}>&nbsp;至&nbsp;</span>
          <DatePicker
            className={styles.filter}
            placeholder={"结束日期"}
            value={endTime}
            onChange={this.onEndTimeChange}
          />
          <span className={styles.filterText}>
            &nbsp;&nbsp;&nbsp;&nbsp;姓名：
          </span>
          <Select
            mode={"multiple"}
            allowClear
            // showSearch
            placeholder={"按姓名查找"}
            className={styles.filterSelect}
            onChange={this.onNameChange}
          >
            {this.getOptions()}
          </Select>
          <Button
            className={styles.exportBtn}
            type={"primary"}
            onClick={this.onExportClick}
          >
            导出为Excel
          </Button>
        </div>
        <div className={styles.main}>
          <Table
            // pagination={false}
            size={"middle"}
            columns={this.columns}
            dataSource={data}
            rowKey={"id"}
          />
        </div>
        <div className={styles.map}>
            <GoogleMapReact
                defaultCenter={defaultCenter}
                defaultZoom={defaultZoom}
                bootstrapURLKeys={{key: config.MAP_KEY}}
                center={
                    showLatitude && showLongitude ?
                    {lat: showLatitude, lng: showLongitude} : defaultCenter
                }
            >
                <MapMarker
                    lat={showLatitude ? showLatitude : office.lat}
                    lng={showLongitude ? showLongitude :  office.lng}
                    text={this.getMarkerText()}
                />
            </GoogleMapReact>
        </div>

        <Modal
          title={"编辑记录"}
          visible={showModal}
          maskClosable={false}
          onCancel={!loading && this.onCancelModalClick}
          footer={[
            <Button
              disabled={loading}
              key={"cancel"}
              onClick={this.onCancelModalClick}
            >
              取消
            </Button>,
            <Button
              disabled={loading}
              loading={loading}
              key={"submit"}
              type={"primary"}
              onClick={this.onEditModalSubmit}
            >
              提交
            </Button>
          ]}
        >
          <div className={styles.infoSet}>
            <span className={styles.infoTitle}>
              真实姓名：
            </span>
            <span className={styles.infoValue}>
              {editingRecord && this.getRealNameByOpenId(editingRecord.openId)}
            </span>
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitle}>
              登入时间：
            </span>
            <span className={styles.infoValue}>
              {editingRecord && editingRecord.timeIn ? utcToLocalTime(editingRecord.timeIn) : "-"}
            </span>
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitle}>
              登入位置：
            </span>
            <span className={styles.infoValue}>
              {
                editingRecord ?
                  this.makeLocation(editingRecord.longitudeIn, editingRecord.latitudeIn) :
                  this.makeLocation(null, null)
              }
            </span>
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitle}>
              登出时间：
            </span>
            <span className={styles.infoValue}>
              {editingRecord && editingRecord.timeIn ? utcToLocalTime(editingRecord.timeOut) : "-"}
            </span>
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitle}>
              登出位置：
            </span>
            <span className={styles.infoValue}>
              {
                editingRecord ?
                  this.makeLocation(editingRecord.longitudeOut, editingRecord.latitudeOut) :
                  this.makeLocation(null, null)
              }
            </span>
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitleLine}>
              备注：
            </span>
            <TextArea
              rows={2}
              value={editingRecord ? editingRecord.comment : ""}
              onChange={this.onCommentEdit}
            />
          </div>
          <div className={styles.infoSet}>
            <span className={styles.infoTitleLine}>
              图片：
            </span>
            <div>
              {this.renderEditingImageList(editingRecord)}
            </div>
            <Divider />
            <Upload {...props}>
              <Button
                icon={<UploadOutlined />}
              >
                上传图片
              </Button>
            </Upload>
          </div>
        </Modal>

        <Image
          src={imgToShow}
          style={{
            display: 'none',
          }}
          preview={{
            visible: showImg,
            src: imgToShow,
            onVisibleChange: (showImg) => {
              console.log("onVisibleChange:", showImg)
              this.setState({showImg});
            }
          }}
        />
      </div>
    )
  }
}

export default Records;
