import api from "./api";
import config from "../config";

const GET = "GET";
const POST = "POST";
const DELETE = "DELETE";
const PUT = "PUT";

export const login = data => {
    return api.request({
        url: `/admin/login`,
        method: POST,
        data,
    })
};

export const logout = () => {
    return api.request({
        url: `/admin/logout`,
        method: GET,
    })
};

export const currentUser = () => {
    return api.request({
        url: `/admin/current_user`,
        method: GET,
    })
};

export const listUser = () => {
    return api.request({
        url: `/admin/user/list`,
        method: GET,
    })
}

export const updateUser = (data) => {
    return api.request({
        url: `/admin/user/update`,
        method: PUT,
        data,
    })
}

export const deleteUser = (openId) => {
    return api.request({
        url: `/admin/user/delete/${openId}`,
        method: DELETE
    })
}

export const listRecords = (startTime, endTime, openId) => {
    return api.request({
        url: `/admin/record/list?startTime=${startTime}&endTime=${endTime}&openId=${openId}`,
        method: GET
    })
}

export const getRecordById = (recordId) => {
    return api.request({
        url: `/admin/record/get/${recordId}`,
        method: GET
    })
}

export const updateRecord = data => {
    return api.request({
        url: `/admin/record/update`,
        method: PUT,
        data
    })
}

export const listSites = () => {
    return api.request({
        url: `/admin/site/list`,
        method: GET
    })
}

export const deleteSite = (id) => {
    return api.request({
        url: `/admin/site/delete/${id}`,
        method: DELETE
    })
}

export const updateSite = (data) => {
    return api.request({
        url: `/admin/site/update`,
        method: PUT,
        data,
    })
}

export const createSite = (data) => {
    return api.request({
        url: `/admin/site/create`,
        method: POST,
        data,
    })
}


export const listTasks = () => {
    return api.request({
        url: `/admin/task/list`,
        method: GET
    })
}

export const deleteTask = (id) => {
    return api.request({
        url: `/admin/task/delete/${id}`,
        method: DELETE
    })
}

export const updateTask = (data) => {
    return api.request({
        url: `/admin/task/update`,
        method: PUT,
        data,
    })
}

export const createTask = (data) => {
    return api.request({
        url: `/admin/task/create`,
        method: POST,
        data,
    })
}

export const getImgByImgLinkId = (id) => {
    return api.request({
        url: `/admin/file/get/${id}`,
        method: GET,
        responseType: "blob"
    })
}

export const deleteImgLink = (id) => {
    return api.request({
        url: `/admin/file/delete/${id}`,
        method: DELETE
    })
}

// export const getUploadFileUrl = (recordId) => {
//     return `${config.BASE_URL}admin/file/upload/${recordId}`;
// }

export const uploadFile = (recordId, data) => {
    return api.request({
        url: `admin/file/upload/${recordId}`,
        method: POST,
        data,
        headers: {
            'Content-Type': 'multipart/form-data;',
            'is-form-data': "true"
        },
    })
}
