import React from "react";
import 'antd/dist/antd.css';
import {Layout, Spin,} from 'antd';
import styles from "./index.module.less";
import 'ant-design-pro/dist/ant-design-pro.css';
import {Redirect, withRouter} from "react-router";
import {currentUser} from "../service";
import {message} from "../lib";
import MyMenu from "../components/MyMenu";
import {inject, observer} from "mobx-react";

const {Content} = Layout;


@inject('stores')
@observer
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorMsg: "",
        };
        this.menuRef = React.createRef();
    }

    componentDidMount = async () => {
        try {
            let res = await currentUser();
            if (res.code === 401 && !this.props.stores.AuthStore.isLogin) return;
            if (res.error) throw res.error;
            if (res.data) {
                const user = res.data;
                this.props.stores.AuthStore.setUser(user);
            }
        } catch (e) {
            if (e.code !== 401 || !this.props.stores.AuthStore.isLogin) {
                message.error(e.message);
            }
        } finally {
            this.setState({loading: false})
        }
    };

    calcMainWidth = () => {
        if (!this.menuRef.current) {
            return {}
        }
        const {offsetWidth} = this.menuRef.current;
        if (offsetWidth) {
            return {width: offsetWidth}
        } else {
            return {}
        }
    }

    render() {
        if (this.state.loading) {
            return <Spin className={styles.spin} tip={"System Loading"}/>
        }
        if (this.props.stores.AuthStore.isLogin) {
            return (
                <Layout className={styles.layout}>
                    <MyMenu forwardRef={this.menuRef}/>
                    <Content className={styles.wrap} style={this.calcMainWidth()}>
                        {this.props.children}
                    </Content>
                </Layout>
            )
        }
        return <Redirect to={'/login'}/>
    }
}

export default withRouter(Main);
