import React from "react";
import styles from "./index.module.less";
import {withRouter} from "react-router";
import {Button, Form, Input} from "antd";
import {UserOutlined, LockOutlined,} from  '@ant-design/icons';
import {login} from "../../service";
import {inject, observer} from "mobx-react";
import config from "../../config";
import {message} from "../../lib";
import appInfo from "../../../package.json";

@inject("stores")
@observer
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitLoading: false,
            focused: false,
            // errorMsg: '',
        };
        this.formRef = React.createRef();
    }

    handleFormSubmit = (values) => {
        this.setState({
            submitLoading: true,
        }, async () => {
            try {
                const {setUser, setToken, saveToken,} = this.props.stores.AuthStore;
                const {username, pwd} = values;
                const res = await login({username, pwd});
                if (res.error) throw res.error;
                if (res.token) {
                    setToken(res.token);
                    saveToken(res.token);
                }
                const {data} = res;
                if (data && data.user) {
                    setUser(data.user);
                }
                this.props.history.push(config.MENU_ITEMS.USERS);

            } catch (e) {
                message.error(e.message, 5);
                this.setState({submitLoading: false});
            }
        })
    };

    handleFocus = () => {
        this.setState({focused: true})
    };

    handleBlur = () => {
        this.setState({focused: false})
    };

    render() {
        return (
            <div className={styles.container}>
                <h1 className={styles.header}>
                    ORIENT CONSTRUCTION<br/>
                    打卡小程序管理网站
                </h1>
                <p className={styles.version}>{appInfo.version ? "v" + appInfo.version : ""}</p>
                {/*<img className={styles.logo} src={require("../../static/img/merged_logo.png")} alt="logo"/>*/}
                <div className={this.state.focused ? styles.formWrapFocused : styles.formWrapBlur}>
                    <Form
                        className={styles.form}
                        ref={this.formRef}
                        onFinish={this.handleFormSubmit}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: '请输入用户名' }]}
                        >
                            <Input
                                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder="用户名"
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                            />
                        </Form.Item>

                        <Form.Item
                            name="pwd"
                            rules={[{ required: true, message: '请输入密码' }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                                placeholder="密码"
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                            />
                        </Form.Item>

                        <Form.Item>
                            <span className={styles.buttonGap}/>
                            <Button
                                className={styles.button}
                                type="primary"
                                htmlType="submit"
                                onFocus={this.handleFocus}
                                onBlur={this.handleBlur}
                                loading={this.state.submitLoading}
                                disabled={this.state.submitLoading}
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}

export default withRouter(Login);
