import React from 'react';
import 'antd/dist/antd.css';
import Main from "./main";
import {Route, Switch} from "react-router";
import {createBrowserHistory} from "history";
import Users from "./pages/Users";
import Records from "./pages/Records";
// import Profile from "./pages/Profile";
import Login from "./pages/Login";
// import Register from "./pages/Register";
// import ResetPassword from "./pages/ResetPassword";
import {BrowserRouter as Router} from "react-router-dom";
import config from "./config";
import Sites from "./pages/Sites";
import Tasks from "./pages/Tasks";


class App extends React.Component {

    render() {
        const history = createBrowserHistory();

        return (
            <Router history={history}>

                    <Switch>
                        <Route exact path="/login" component={Login}/>
                        {/*<Route exact path="/register" component={Register}/>*/}
                        {/*<Route exact path="/reset-password" component={ResetPassword}/>*/}
                        <Main>
                            <Route exact path={config.MENU_ITEMS.USERS} component={Users}/>
                            <Route path={config.MENU_ITEMS.RECORDS} component={Records}/>
                            <Route path={config.MENU_ITEMS.SITES} component={Sites}/>
                            <Route path={config.MENU_ITEMS.TASKS} component={Tasks}/>
                            {/*<Route path="/profile" component={Profile}/>*/}
                            {/*<Redirect to="/"/>*/}
                        </Main>
                    </Switch>
                {/*</Layout>*/}
            </Router>
        );
    }
}
export default App;
