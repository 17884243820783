import React from "react";
import {Avatar, Layout, Menu, Modal} from "antd";
import {Link, withRouter} from "react-router-dom";
import {
    TeamOutlined,
    FieldTimeOutlined,
    LogoutOutlined,
    UserOutlined,
    ExclamationCircleOutlined,
    EnvironmentOutlined,
    FileDoneOutlined
} from '@ant-design/icons';
import styles from "./index.module.less";
import config from "../../config";
import {inject, observer} from "mobx-react";
import {logout} from "../../service";
import {message} from "../../lib";

const {Sider} = Layout;

@inject("stores")
@observer
class MyMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
        }
    }

    onCollapse = () => {
        const collapsed = !this.state.collapsed;
        this.setState({collapsed});
    };

    logout = () => {
        Modal.confirm({
            title: "Do you want to sign out?",
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                console.log("myMenu 34")
                const res = await logout();
                if (res.error) {
                    message.error(res.error.message);
                }
                this.props.history.push("/login");
            },
        });
    };

    render() {
        return (
            <Sider
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapse}
            >
                <div className={styles.userInfo}>
                    <div>
                        <Avatar
                            icon={<UserOutlined />}
                            className="menu-avatar"
                        />
                    </div>
                    {
                        !this.state.collapsed &&
                        <div className={styles.username}>{this.props.stores.AuthStore.currentUser.username}</div>
                    }
                </div>

                <Menu theme="dark" mode="inline" selectedKeys={[this.props.location.pathname]}>

                    <Menu.Item key={config.MENU_ITEMS.USERS}>
                        <Link to={config.MENU_ITEMS.USERS}>
                            <TeamOutlined className={styles.icon} />
                            <span>用户 | Users</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key={config.MENU_ITEMS.RECORDS}>
                        <Link to={config.MENU_ITEMS.RECORDS}>
                            <FieldTimeOutlined className={styles.icon} />
                            <span>记录 | Records</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key={config.MENU_ITEMS.SITES}>
                        <Link to={config.MENU_ITEMS.SITES}>
                            <EnvironmentOutlined className={styles.icon}/>
                            <span>工地 | Sites</span>
                        </Link>
                    </Menu.Item>

                    <Menu.Item key={config.MENU_ITEMS.TASKS}>
                        <Link to={config.MENU_ITEMS.TASKS}>
                            <FileDoneOutlined className={styles.icon}/>
                            <span>任务 | Tasks</span>
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item key={config.MENU_ITEMS.PROFILE}>*/}
                    {/*    <Link to={config.MENU_ITEMS.PROFILE}>*/}
                    {/*        <ProfileOutlined className={styles.icon} />*/}
                    {/*        <span>Profile</span>*/}
                    {/*    </Link>*/}
                    {/*</Menu.Item>*/}


                </Menu>

                <Menu theme="dark" mode="inline" className={styles.signOut}>
                    <Menu.Item key="signOut" onClick={this.logout}>
                        <LogoutOutlined className={styles.icon} />
                        <span>登出 | Sign out</span>
                    </Menu.Item>
                </Menu>

            </Sider>
        )
    }
}

export default withRouter(MyMenu)
