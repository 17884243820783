import React from "react";
import styles from "./index.module.less";

export default class PageTitle extends React.Component {
    render() {
        return (
            <h1 className={styles.pageTitle}>{this.props.children}</h1>
        )
    }
}
